<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title text-truncate"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              handshake
            </v-icon>

            <span class="text-truncate">
              Partnership
            </span>
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Brand Partnership
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <v-row>
            <!-- Columns Section -->
            <v-col
              v-for="kind in ['influencer']"
              :key="kind"
              cols="12"
              md="12"
            >
              <div class="mb-1 d-flex" style="width: 200px">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon
                    :class="{ 'black--text': isThemeLight }"
                    class="mr-1"
                    left
                  >
                    {{ kind === 'audience' ? 'people' : 'volume_down' }}
                  </v-icon>

                  {{ capitalizeString(kind) }}
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipTexts[kind] }}
                  </span>
                </v-tooltip>
              </div>

              <!-- <v-autocomplete
                v-model="form[kind]"
                :items="searchItems"
                label="Brands"
                dense
                eager
                outlined
                :append-icon="null"
                :loading="isLoadingSearchResults"
                return-object
                hide-details
                item-text="name"
                item-value="id"
                no-filter
                @change="submitForm(kind)"
                :search-input.sync="searchQuery[kind]"
                @update:search-input="doSearch(kind)"
                :disabled="form.any"
              >
                <template v-slot:no-data>
                  <div v-if="isLoadingSearchResults" class="text-center py-3">
                    <v-progress-circular
                      :width="3"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                  <div v-else class="text-center py-2">
                    No results found.
                  </div>
                </template>
              </v-autocomplete> -->

              <div v-if="appliedFiltersFor[kind].length" class="mt-3">
                <div
                  v-for="item in appliedFiltersFor[kind]"
                  :key="item.id"
                >
                  <div class="d-flex justify-space-between align-center pb-1 pt-3">
                    {{ item.data.inputs.name }}

                    <v-btn icon color="red" @click="removeFilterItem(item)">
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-space-between align-center mt-3 pr-1">
                <v-checkbox
                  v-model="form.any"
                  hide-details
                  label="Any"
                  class="mt-0"
                  @change="submitAny"
                ></v-checkbox>

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipTexts.any }}
                  </span>
                </v-tooltip> -->
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "partnership"

const originalForm = () => ({
  any: false,
  influencer: null
})

export default {
  name: "FilterPartnership",

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,
    isLoadingSearchResults: false,
    searchQuery: {
      influencer: ""
    },

    searchItems: [],

    _timerId: null,

    tooltipTexts: {
      any: "Refine search results with only influencers with at least one sponsored post for any brand.",
      influencer: "Identify influencers by having sponsored posts with brands. We detect sponsored posts either by usage of commercial hashtags or official paid partnership tag. You can add several brands and your search results will be refined with influencers that have sponsored posts for ANY of the specified brands."
    }
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // get a list of all of them
    appliedFilters() {
      return this.$store.getters["influencerDiscovery/findFiltersByType"](filterName)
    },

    appliedFiltersFor() {
      return {
        influencer: this.appliedFilters.filter((item) => item.data.kind === "influencer")
      }
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    }
  },

  validations: {
    form: {
      any: {},
      influencer: {}
    }
  },

  methods: {
    /**
     * Takes an item and generates the text
     */
    computeFilterText({ name }) {
      return `Partnership: ${name}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm(kind) {
      await this.$v.form[kind].$touch()

      if (this.$v.form[kind].$anyError) return

      this.$store.dispatch("influencerDiscovery/addFilter", {
        type: filterName,
        data: {
          color: "lightblue lighten-5",
          icon: "handshake",
          iconColor: "lightblue",
          kind,
          text: this.computeFilterText({ name: this.form[kind].name }),
          inputs: {
            ...this.form[kind]
          }
        }
      })

      // reset the search query input
      this.searchQuery[kind] = ""
      // reset the form input to allow more values
      this.form[kind] = null
      // finally hide the modal
      this.shouldShowModal = false
      // reset the search results
      this.searchItems = []
    },

    /**
     * Deletes all applied filters and disables the input
     */
    async submitAny() {
      await this.$v.form.any.$touch()

      if (this.$v.form.any.$anyError) return

      // Remove all other filters for this type
      await this.$store.dispatch("influencerDiscovery/removeFilters", filterName)

      // only add if the value is true
      if (this.form.any) {
        this.$store.dispatch("influencerDiscovery/addFilter", {
          type: filterName,
          data: {
            color: "lightblue lighten-5",
            icon: "handshake",
            iconColor: "lightblue",
            kind: "any",
            text: this.computeFilterText({ name: "Any" }),
            inputs: {
              ...this.form.any
            }
          }
        })
      }
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem(item) {
      this.$store.dispatch("influencerDiscovery/removeFilter", item.id)
    },

    /**
     * Make API request to fetch the select options for autocomplete
     *
     * @param {String} kind
     */
    doSearch(kind) {
      // cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        // if input is empty, don't continue
        if (!this.searchQuery[kind]) return

        // if a request is being made, wait for it
        if (this.isLoadingSearchResults) return

        // show the loader while request is being made
        this.isLoadingSearchResults = true

        try {
          const response = await axios({
            url: "/api/filters/brands/?q=" + this.searchQuery[kind],
          })

          // the API responds like
          this.searchItems = response.data
        } catch (error) {
          // log using the helper function
          logger({ type: "Network Error", error })
        } finally {
          this.isLoadingSearchResults = false
        }
      }, 500)
    }
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      if (e.detail.item.data.kind === "any") {
        this.form.any = false
      }
    })
  }
}
</script>
